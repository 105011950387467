import React from "react";
import Hero from "../Hero";
import Spacing from "../Spacing";
// import FunFact from "../FunFact";
import About from "../About";
import WhyChose from "../WhyChose";
import Service from "../Service";
import Button from "../Button";
import SectionHeading from "../SectionHeading";
import Cta from "../Cta";
import { pageTitle } from "../../helpers/PageTitle";
import Step from "../Step";
import AboutStyle2 from "../About/AboutStyle2";
import FunFact from "../FunFact";

const funfactData = [
  { title: "Days to first earnings", number: "14" },
  { title: "Days to full launch", number: "90" },
  { title: "Beta testers", number: "24" },
  { title: "Qualified leads", number: "1000+" },
];
const whyChoseFeatureData = [
  {
    title: "No followers/subscribers/fans needed",
    content:
      "You don't need a big following to create and sell a course for 7-figures. While it helps initially, success depends on effective marketing, funnels and great course content.",
  },
  {
    title: "Immediate earnings potential",
    content:
      "Selling to beta testers and pre-selling the course are key parts of our strategy. Beta testers help us refine the course, ensuring it achieves product-market fit before a full launch.",
  },
  {
    title: "Data-driven, scientific process",
    content:
      "Our process is made based on user data and actually making and scaling courses. The process is as scientific as you can get a course process. Each step of the way supports the next one.",
  },
];
const serviceListData = [
  {
    title: "Proven track record",
    subtitle:
      "We collaborate only with coaches who have a demonstrated history of success, whether through their own achievements or through their ability to drive results for others.",
  },
  {
    title: "Commitment to excellence",
    subtitle:
      "We expect our coaches to bring a strong dedication to their craft, with a willingness to invest the time, effort, and energy into the course.",
  },
  {
    title: "Productizable process",
    subtitle:
      "Your coaching process should be clearly defined and capable of being transformed into a structured course that takes students A to B.",
  },
  {
    title: "7-Figure potential",
    subtitle:
      "We partner with coaches who not only aspire to seven-figure revenue but have the potential and drive to achieve it.",
  },
];

export default function Home() {
  pageTitle("Launch");
  return (
    <>
      <Hero
        title={[
          "London Based Creative Agency",
          "25+ Years of Experience",
          "30+ Worldwide Partnership",
          "Take World-class Service",
        ]}
        subtitle="Launch and Scale Your Own 7-Figure High-Ticket Course"
        undertitle="Stop trading time for money. Learn how to scale your expertise into a highly profitable course that runs itself. Watch the video below to see how we help coaches build and sclae their own 7-figure course."
        videoSrc="https://www.youtube.com/embed/KH_-X5GHg60"
        bgUrl="/images/launch/thumbnail3.jpg"
      />
      <Spacing lg="50" md="50" />
      <div className="container">
        {/* <FunFact data={funfactData} /> */}
        <div className="text-center">
          <Button btnText="Apply Now (1/4 spots left)" btnUrl="/contact" />
        </div>
      </div>
      <div id="">
        <Spacing lg="125" md="70" />
        <About
          thumbnail="/images/launch/SivertPatrikPodcast.jpg"
          uperTitle="Time is your most valuable asset"
          title="Stop trading time for money and scale infinitely"
          subTitle="As a successful coach or content creator, your time is your most precious resource. Working one-on-one or selling low-ticket products & services can limit your reach and earning potential. Imagine converting your expertise into a high-value, scalable course that generates revenue even while you sleep."
          featureList={[
            "Scale infinitely without having to work more",
            "Build a great community and help others",
            "Risk-free complete done-for-you service",
          ]}
          btnText="Learn More"
          btnUrl="/about"
        />
      </div>
      <section id="case-study-section">
        <div className="cs_height_150 cs_height_lg_75" />
        <AboutStyle2
          thumbnailSrc1="/images/launch/lp2.jpg"
          thumbnailSrc2="/images/launch/afpt-vslfunnel.jpg"
          uperTitle="High-ticket course with Coachable and AFPT"
          title="From 0 to more than $500.000 in 10 months"
          subTitle="Beta group started in August 2023 and the course was launched in January 2024. We successfully launched and scaled the course in the Norwegian market. Through strategic planning, continuous optimization, and leveraging our expertise in digital marketing, we achieved significant revenue and profit. "
          featureList={[
            "Launched in January 2024",
            "$400,000 in profit by June 2024",
            "$200,000 first 30 days (beta group)",
            "About 80% profit margins",
            "Over 1000 qualified leads",
          ]}
          // btnText="Learn More"
          // btnUrl="/about"
        />
      </section>
      <div className="container">
        <Spacing lg="125" md="70" />
        <FunFact data={funfactData} />
      </div>

      <div id="about-section">
        <Spacing lg="185" md="75" />
        <WhyChose
          sectionTitle="20+ courses over the past six years"
          sectionSubTitle="Proven track record"
          whyChoseFeatureData={whyChoseFeatureData}
          thumbnailSrc="/images/launch/services2.jpg"
        />
      </div>

      <Spacing lg="150" md="80" />
      <section id="requirements-section" className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="The coaches we work with"
            subTitle="Requirements"
            variantColor="cs_white_color"
          />
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>
      <section id="process-section">
        <div className="container">
          <Spacing lg="80" md="30" />
          <SectionHeading title="Our Process" subTitle="How we work" />
          <p className="section-description">
            Our approach is rooted in leveraging insights and methodologies
            honed from our extensive course experience. By continuously refining
            our strategies with data and feedback, we ensure that each project
            we undertake excels.
          </p>
          <Spacing lg="50" md="30" />
          <div>
            <Step
              phase="Phase 1"
              title="Validation"
              description="The validation phase is the bedrock of every successful course. We delve deep into your expertise and market potential to ensure we're creating not just a course, but a transformative learning experience. This phase involves in-depth market analysis, strategic planning, and identifying the specific needs of your target audience. Our goal is to develop a clear, strategic blueprint that guides the entire course creation journey."
            />
            <Step
              phase="Phase 2"
              title="Beta testers"
              description="During the beta testing phase, we release a preliminary version of your course to a select group of beta testers. This phase allows us to gather valuable feedback on how real users interact with the course content, identifying strengths and areas for improvement. The insights gained are critical for refining the course before the full launch, ensuring it meets the needs and expectations of your target audience."
            />
            <Step
              phase="Phase 3"
              title="Course creation"
              description="In the course creation phase, we leverage the latest technologies and methodologies to produce a polished, professional course. This phase focuses on developing content that is tailored to the needs identified during the beta testing phase. The course is built on a proven process designed to take your customers from their current situation to their desired outcome."
            />
            <Step
              phase="Phase 4"
              title="Launch and scale"
              description="The launch and scale phase is where we implement advanced marketing strategies and sales funnels to maximize enrollments and revenue. We use data-driven marketing techniques to ensure your course gets the visibility it deserves. This phase is all about scaling your course to reach as many people as possible, continuously monitoring performance metrics and adjusting strategies to maximize growth."
            />
          </div>
        </div>
      </section>
      <Spacing lg="80" md="70" />
      <Cta
        title="Are you interested in launching and scaling a high-ticket course with us?"
        btnText="Apply now (1/4 spots left)"
        btnUrl="/contact"
        variant="cs_type_1"
      />
    </>
  );
}
