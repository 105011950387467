import React, { useEffect } from "react";
import { pageTitle } from "../../helpers/PageTitle";

export default function ContactPage() {
  pageTitle("Contact");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div data-tf-live="01J5WDB9WZX4Y7E1GXWSZTHCQB"></div>
    </>
  );
}
