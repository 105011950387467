import React from "react";
import Button from "../Button";
import parse from "html-react-parser";

export default function Cta({
  title,
  btnText,
  btnUrl,
  bgUrl,
  variant,
  noShape,
}) {
  return (
    <div
      className={`cs_cta cs_style_1 ${
        variant ? variant : ""
      } cs_bg_filed position-relative cs_radius_15 overflow-hidden text-center`}
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      {noShape ? "" : <></>}

      <div className="cs_cta_in position-relative">
        <h2 className="cs_cta_title cs_fs_50 cs_black_color mb-0">
          {parse(title)}
        </h2>
        <div className="cs_height_45 cs_height_lg_30" />
        <Button btnText={btnText} btnUrl={btnUrl} variantColor="cs_btn_white" />
      </div>
    </div>
  );
}
