import React from "react";
import PropTypes from "prop-types";

const Step = ({ phase, title, description }) => {
  return (
    <div className="step">
      <div className="step-header">
        <div className="step-phase">{phase}</div>
        <h2 className="step-title">{title}</h2>
      </div>
      <p className="step-description">{description}</p>
    </div>
  );
};

Step.propTypes = {
  phase: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Step;
