import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/Pages/Home";
import ErrorPage from "./components/Pages/ErrorPage";
import Layout2 from "./components/Layout/Layout2";
import ContactPage from "./components/Pages/ContactPage";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Layout2 />}>
        <Route index element={<Home />} />
      </Route>
      <Route path="*" element={<ErrorPage />} />
      <Route path="contact" element={<ContactPage />} />
    </Routes>
  );
}

export default App;
