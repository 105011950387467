import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="cs_footer_simple">
      <div className="container">
        <div className="cs_bottom_footer">
          <div className="cs_copyright">
            © {new Date().getFullYear()} Launch Technology AS. All rights
            reserved.
          </div>
          <div className="cs_footer_link">
            <Link
              to="https://launched.no"
              target="_blank"
              rel="noopener noreferrer"
            >
              Launched.no
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
